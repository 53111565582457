import uuid from 'uuid/v1';
import {
    ACTION_ADD_GAME,
    ACTION_ADD_SCORE_BOARD,
    ACTION_ADD_PLAYER,
    ACTION_ADD_SCORE,
} from '../actions';

export default function(state, action){
    switch(action.type){
        case ACTION_ADD_GAME:
            return {
                ...state,
                games: {
                    [uuid()]: {
                        ...action.payload,
                    },
                    ...state.games,
                },
            };
        case ACTION_ADD_SCORE_BOARD:
            return {
                ...state,
                scoreBoards: {
                    [uuid()]: {
                        ...action.payload,
                    },
                    ...state.scoreBoards,
                },
            };
        case ACTION_ADD_PLAYER:
                return {
                    ...state,
                    players: {
                        [uuid()]: {
                            ...action.payload,
                        },
                        ...state.players,
                    },
                };
        case ACTION_ADD_SCORE:
            return {
                ...state,
                scores: {
                    ...state.scores,
                    [uuid()]: {
                        ...action.payload,
                    }
                },
            };
        default:
            return {
                ...state,
            };
    }
};
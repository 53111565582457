import React, { useState, useEffect } from 'react';
import { useFirebaseMyProfile } from '../../hooks/firebaseHooks';
import { useMyUsername } from '../../hooks/currentPlayerHooks';
import PageWrapper, { PageToolbar } from '../PageWrapper';
import { TextField, Button, Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    usernameField:{
        marginBottom: 20,
    }
})

const ProfileEdit = ({name, onSave})=>{
    const classes = useStyles();
    const [_name, setName] = useState('');
    useEffect(()=>{
        setName(name);
    }, [name]);
    const isSaveable = _name !== name;
    return <>
        <div className={classes.usernameField}>
            <TextField
                label="Nickname"
                onChange={(e)=>setName(e.target.value)}
                value={_name}
            />
        </div>
        <Button
            disabled={!isSaveable}
            variant="outlined"
            onClick={()=> onSave(_name)}
        >
            Speichern
        </Button>
    </>
}

export default function ProfilePage(){
    const [username, setUsername] = useMyUsername();
    const handleSave = (name)=>{
        setUsername(name);
    };

    return (
        <PageWrapper
            toolbar={<PageToolbar title="Profil"></PageToolbar>}
        >
            <Box p={2}>
                {(username)?
                    <ProfileEdit name={username} onSave={handleSave} />
                    :
                    <Typography>Loading...</Typography>
                }
            </Box>
        </PageWrapper>
    )
}
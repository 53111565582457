import { useSelector, useDispatch } from "react-redux";
import { useFirebasePlayersList } from "./firebaseHooks";
import { actionSetLoginEmail } from "../data/actions";

const useSession = () => useSelector(state => state.session );

export const useLoginEmail = () => {
    const email = useSelector(state => state.session.loginEmail);
    const dispatch = useDispatch();
    return [email, (_email)=>{
        dispatch(actionSetLoginEmail(_email));
    }];
}

export const useSessionPlayerId = ()=> useSession().playerId;

export const useSessionPlayer = () =>{
    const playerId = useSessionPlayerId();
    return useFirebasePlayersList().find( player => player.id === playerId );
};
import { isLoaded, useFirebase, isEmpty } from "react-redux-firebase"
import { useSelector } from "react-redux"

export const useAuthCurrentUser = ()=> useFirebase().auth().currentUser;
export const useAuth = ()=> useSelector(state => state.firebase.auth);
export const useAuthUid = () => useAuth().uid;
export const useAuthIsLoaded = ()=> isLoaded(useAuth());
export const useAuthIsEmpty = () => isEmpty(useAuth());
export const useAuthIsSignedIn = () => {
    const isLoaded = useAuthIsLoaded();
    const isEmpty = useAuthIsEmpty();
    const uid = useAuthUid();
    return isLoaded && !isEmpty && uid;
}

export const objectToArrayWithId = (dataset)=>{
    return Object.keys(dataset).map(id=>{
        return {
            ...dataset[id],
            id,
        };
    });
}

export const filterScoreBoardsByGameId = (scoreBoards, gameId)=>{
    return scoreBoards.filter(scoreBoard => scoreBoard.gameId === gameId );
}

export const filterScoresByScoreBoardId = (scores, scoreBoardId) =>{
    return scores.filter( score => score.scoreBoardId === scoreBoardId);
}

export const filterUnique = (array) => {
    return array.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
}
import React, { useState } from 'react';
import { TextField, Fab, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import slugify from 'slugify';
import { useFirebasePushScoreboard, useFirebaseScoreboardsListByGameId } from '../hooks/firebaseHooks';

const AddScoreBoard = ({gameId})=>{
    const [name, setName] = useState("");
    const slug = slugify(name);

    const scoreBoards = useFirebaseScoreboardsListByGameId(gameId);
    const pushScoreboard = useFirebasePushScoreboard();

    const isNameTooShort = name.length < 2;
    const isSlugAlreadyUsed = typeof scoreBoards.find(item => item.slug === slug) !== typeof undefined;

    const isValid = (
        !isNameTooShort
        &&
        !isSlugAlreadyUsed
    );

    const errorSlugAlreadyUsed = (isSlugAlreadyUsed && !isNameTooShort)?
        "Slug is already taken.":null;

    // check if slug exists

    return <>
        <TextField label="Name" value={name} onChange={(e)=> setName(e.target.value)} />
        <TextField label="Slug" disabled value={slug} helperText={errorSlugAlreadyUsed} />
        <Button
            variant="outlined"
            disabled={!isValid}
            onClick={()=>{
                if(!isValid) return;
                pushScoreboard(gameId, slugify(name), name)
                setName("");
            }}
            startIcon={<AddIcon />}
        >
            New Score Board
        </Button>
    </>
};

export default AddScoreBoard;
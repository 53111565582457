import { useSelector } from "react-redux";
import { filterScoreBoardsByGameId, objectToArrayWithId } from "../utils/transformations";

const useScoreBoards = () => useSelector(state => state.localStorage.scoreBoards) || [];

export const useScoreBoardById = (id) => useScoreBoards()[id];

export const useScoreBoardsList = () => objectToArrayWithId(useScoreBoards());

export const useScoreBoardsByGameId = (gameId) => filterScoreBoardsByGameId(
    useScoreBoardsList(),
    gameId
);

export const useScoreBoardByGameIdAndScoreBoardSlug = (gameId, scoreBoardSlug) => useScoreBoardsByGameId(
    gameId
).find( sb => sb.slug === scoreBoardSlug );
import React from 'react';
import { useLoginWithEmail } from "../../hooks/loginWithEMailHooks";
import LoginBox from '../login/LoginBox';
import LoginSendEmail from '../login/LoginSendEmail';
import LoginRetry from '../login/LoginRetry';
import { useAuthIsLoaded } from '../../hooks/useAuth';
import { Typography } from '@material-ui/core';

export default function PlayerPage(){

    const isAuthLoaded = useAuthIsLoaded();

    const [
        sendSignInLinkToEmail,
        isEmailSent,
        isSignInWithEmailLink,
        {code, message}
    ] = useLoginWithEmail();

    let isSending = false;

    return (
        <LoginBox>
            {!isAuthLoaded?
                <Typography style={{textAlign: "center"}}>Waiting for auth...</Typography>
                :
                isEmailSent?
                    <LoginRetry />
                    :
                    <LoginSendEmail
                        onSendEmail={(email)=>{
                            if(isSending) return;
                            isSending = true;
                            sendSignInLinkToEmail(email)
                        }}
                    />
            }
        </LoginBox>
    );
}
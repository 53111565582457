import { useFirebase } from "react-redux-firebase";
import { useState } from "react";
import { useLoginEmail } from './sessionHooks'

export const useLoginWithEmail = ()=>{
    const firebase = useFirebase();
    const [loginEmail, setLoginEmail] = useLoginEmail();
    const [error, setError] = useState({});

    let isSignInWithEmailLink = false;

    if ( loginEmail && firebase.auth().isSignInWithEmailLink(window.location.href)) {
        firebase.auth()
            .signInWithEmailLink(loginEmail, window.location.href)
            .then(function(result) {
                console.log(result);
                setLoginEmail(undefined);
                window.location.href = "/";
            })
            .catch(function(error) {
                console.log("catch");
                console.error(error);
                setError(error);
            });
        isSignInWithEmailLink = true;
    }

    return [
        (email) => {
            firebase.auth()
                .sendSignInLinkToEmail(email, {
                    url: window.location.origin,
                    handleCodeInApp: true,
                }).then(()=>{
                    setLoginEmail(email);
                }).catch((error)=>{
                    setLoginEmail(undefined);
                    setError(error);
                });
        },
        (typeof loginEmail !== typeof undefined && loginEmail !== ""),
        isSignInWithEmailLink,
        error
    ];
};
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { usePlayersList } from '../hooks/playerHooks';
import { useFirebasePlayersList } from '../hooks/firebaseHooks';
import { filterUnique } from '../utils/transformations';
import { Box } from '@material-ui/core';

const getPlayerName = (players, score) => {
    console.log(players, score);
    const p = players.find(p=> p.id === score.playerId);
    return p && p.name || "No Name";
}

const ScoresTable = ({scores})=>{

    const players = useFirebasePlayersList();

    return <TableContainer>
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>
                        User
                    </TableCell>
                    <TableCell>
                        Score
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {scores.sort((a,b) => {
                    return b.score - a.score;
                }).map(s => {
                    return  <TableRow key={s.id}>
                        <TableCell component="th" scope="row">
                            {getPlayerName(players, s)}
                        </TableCell>
                        <TableCell>
                            {s.score}
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ScoresTable;
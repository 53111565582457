import {
    ACTION_SET_LOGIN_EMAIL,
} from '../actions';

export default function(state, action){
    switch(action.type){
        case ACTION_SET_LOGIN_EMAIL:
            return {
                ...state,
                loginEmail: action.payload,
            }
        default:
            return {
                ...state,
            };
    }
};
import React from 'react';
import { Typography, Box } from '@material-ui/core';
import LoginButton from './LoginButton';
import { useLoginEmail } from '../../hooks/sessionHooks';


export default function LoginRetry(){

    const [loginEmail, setLoginEmail] = useLoginEmail();

    return (
        <>
            <Typography style={{marginBottom: 20, textAlign: "center"}}>
                Wir haben dir eine Email mit Link zur Authentifizierung an {loginEmail} gesendet.
            </Typography>
            <Typography variant="body2"  style={{marginBottom: 20, textAlign: "center"}}>
                Keine Email erhalten?
            </Typography>
            <Box p={1}>
                <LoginButton
                    label="Erneut versuchen"
                    onClick={()=> setLoginEmail(undefined)}
                />
            </Box>
        </>
    )
}
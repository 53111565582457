import React from 'react';
import { Paper, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        marginTop: (window.innerHeight > 450)? 60: 20,
        maxWidth: 500,
    },
}));

export default function LoginBox({children}){
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Box p={2}>
                {children}
            </Box>
        </Paper>
    );
};

import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { linkToScoreBoard } from '../utils/links';

const ScoreBoardsList = ({scoreBoards, onClick})=> {
    return <List>
        {scoreBoards.map(({slug, name})=>
            <ListItem
                divider
                button
                key={slug}
                onClick={()=> onClick(slug)}
            >
                <Typography>{name}</Typography>
            </ListItem>
        )}
    </List>
}

export default ScoreBoardsList;
import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import slugify from 'slugify';
import { useFirebasePushGame, useFirebaseGamesList } from '../hooks/firebaseHooks';

const AddGame = ()=>{
    const [name, setName] = useState("");
    const slug = slugify(name);

    const pushGame = useFirebasePushGame();
    const games = useFirebaseGamesList();

    if(!games) return <p>loading...</p>;

    const isNameTooShort = name.length < 2;
    const isSlugAlreadyUsed = typeof games.find(g => g.slug === slug) !== typeof undefined;

    const isValid = (
        !isNameTooShort
        &&
        !isSlugAlreadyUsed
    );

    const errorSlugAlreadyUsed = (isSlugAlreadyUsed && !isNameTooShort)?
        "Slug is already taken.":null;

    // check if slug exists

    return <>
        <TextField label="Name" value={name} onChange={(e)=> setName(e.target.value)} />
        <TextField label="Slug" disabled value={slug} helperText={errorSlugAlreadyUsed} />
        <Button
            variant="outlined"
            disabled={!isValid}
            onClick={()=>{
                if(!isValid) return;
                pushGame(slugify(name), name);
                setName("");
            }}
            startIcon={<AddIcon />}
        >
            Neues Spiel
        </Button>
    </>
};

export default AddGame;
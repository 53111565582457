import {compose, combineReducers, createStore} from 'redux';
import persistState from 'redux-localstorage'
import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database';
import { firebaseReducer } from 'react-redux-firebase'
import uiReducer from './reducers/uiReducer';
import localStorageReducer from './reducers/localStorageReducer';
import sessionReducer from './reducers/sessionReducer';

export const firebaseConfig = {
    apiKey: "AIzaSyDDHuFKNyFb67BKhsTuSya37mmfc9SutKk",
    authDomain: "scoreboard-6fc35.firebaseapp.com",
    databaseURL: "https://scoreboard-6fc35.firebaseio.com",
    projectId: "scoreboard-6fc35",
    storageBucket: "scoreboard-6fc35.appspot.com",
    messagingSenderId: "301189747515",
    appId: "1:301189747515:web:1429ea906fea94dbf31d1e",
    measurementId: "G-WR3N2GDXJ4"
};

firebase.initializeApp(firebaseConfig);

export default (initialState = {
    firebase: {},
    // localStorage: {
    //     games: {},
    //     scoreBoards: {},
    //     players: {},
    //     scores: {},
    // },
    session: { },
    ui: {
        playerId: null,
    },
})=>{

    const persistStateInstance = persistState(["localStorage", "session"]);
    const enhencer = (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "function")?
        compose(persistStateInstance,window.__REDUX_DEVTOOLS_EXTENSION__())
        :
        compose(persistStateInstance);

    return createStore(
        combineReducers({
            // localStorage: localStorageReducer,
            session: sessionReducer,
            firebase: firebaseReducer,
            ui: uiReducer,
        }),
        initialState,
        enhencer
    );
};
import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useFirebasePushScore, useFirebasePlayersList } from '../hooks/firebaseHooks';
import { useAuthUid } from '../hooks/useAuth';

const AddScore = ({scoreboardId})=>{

    const playerAuthId = useAuthUid();
    const players = useFirebasePlayersList().filter(p=> typeof p.id !== typeof undefined);
    const [selectedPlayerId, setPlayerId] = useState('');

    useEffect(()=>{
        if(players.find(p => p.id === playerAuthId)) setPlayerId(playerAuthId);
    }, [players.length, playerAuthId]);

    const [score, setScore] = useState("");
    const pushScore = useFirebasePushScore();

    const isValid = score !== "" && parseInt(score)+"" === score;

    return <>
        <FormControl>
            <InputLabel>Player</InputLabel>
            <Select
                value={selectedPlayerId}
                onChange={(e)=> setPlayerId(e.target.value) }
            >
                {players.map(p => <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>)}
            </Select>
        </FormControl>
        <TextField type="number" label="Score" value={score} onChange={(e)=> setScore(e.target.value)} />
        <Button
            variant="outlined"
            onClick={()=>{
                const isValid = (score.length > 0 && parseInt(score)+"" === score);
                if(!isValid) return;
                pushScore(scoreboardId, selectedPlayerId, score);
                setScore("");
            }}
            disabled={!isValid}
            startIcon={<AddIcon />}
        >
            New Score
        </Button>
    </>
};

export default AddScore;
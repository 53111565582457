import React, { useState } from 'react';
import { Typography, Box, TextField, makeStyles } from '@material-ui/core';
import LoginButton from './LoginButton';

const useStyles = makeStyles({
    textField: {
        width: "100%",
        marginBottom: 10,
    }
})

export default function LoginSendEmail({onSendEmail}){

    const classes = useStyles();

    const [email, setEMail] = useState("");

    const handleSendEmail = ()=>{
        // TODO: validate email
        onSendEmail(email)
    };

    return (
        <>
            <Typography style={{marginBottom: 20, textAlign: "center"}}>
                Gib deine Email Adresse ein um einen Account zu erstellen
                oder dich mit deinem bestehenden Account einzuloggen.
            </Typography>
            <Box p={1}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="E-Mail"
                    value={email}
                    onChange={(e)=> setEMail(e.target.value)}
                />
                <LoginButton
                    label="Send Login Link"
                    onClick={handleSendEmail}
                />
            </Box>
        </>
    )
}
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Typography } from '@material-ui/core';

const GamesList = ({games, onClick})=> {

    return <List>
        {games.map(({id, slug, name})=>
            <ListItem
                divider
                button
                key={id}
                onClick={()=> onClick(slug)}
            >
                <Typography>{name}</Typography>
            </ListItem>
        )}
    </List>
}

export default GamesList;
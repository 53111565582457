import React from 'react';
import { Grid, Button } from '@material-ui/core';

export default function LoginButton({label, onClick}){
    return (
        <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
        >
            <Grid item xs="auto">
            <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            >
                {label}
            </Button>
            </Grid>
        </Grid>
    )
}
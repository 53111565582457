
import React from 'react';
import {render} from 'react-dom';
import Store from './data/store';
import App from './components/App'

// load data
const store = Store();

// render App
render(
    <App store={store} />,
    document.getElementById("app")
);

const buildAction = ( action, payload ) =>{
    return { type: action, payload };
};

export const ACTION_SET_LOGIN_EMAIL = "set_login_email";
export const ACTION_UNSET_LOGIN_EMAIL = "unset_login_email";

export const actionSetLoginEmail = (email) => buildAction(
    ACTION_SET_LOGIN_EMAIL,
    email
);

// DEPRECATED

export const ACTION_ADD_GAME = "add_game";
export const ACTION_ADD_SCORE_BOARD = "add_score_board";
export const ACTION_ADD_PLAYER = "add_player";
export const ACTION_ADD_SCORE = "add_score";

export const ACTION_SELECT_PLAYER = "select_player";

export const actionAddGame = ( slug, name ) => buildAction(
    ACTION_ADD_GAME,
    { slug, name }
);

export const actionAddScoreBoard = ( gameId, slug, name ) => buildAction(
    ACTION_ADD_SCORE_BOARD,
    { gameId, slug, name}
);

export const actionAddPlayer = ( name ) => buildAction(
    ACTION_ADD_PLAYER,
    { name }
)

export const actionAddScore = ( scoreBoardId, playerId, score )=> buildAction(
    ACTION_ADD_SCORE,
    { scoreBoardId, playerId, score }
);

export const actionSelectPlayer = (playerId) => buildAction(
    ACTION_SELECT_PLAYER,
    playerId,
)
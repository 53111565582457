import React from 'react';
import { Paper, AppBar, Toolbar, Box, IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from '../hooks/styleHooks';
import { useHistory } from 'react-router-dom';

const BackButton = ()=>{
    const history = useHistory();
    return (
        <IconButton
            edge="start"
            color="inherit"
            aria-label="Zurück"
            onClick={()=> history.goBack()}
        >
            <ArrowBackIcon />
        </IconButton>
    )
}

export const PageToolbar = ({
    children,
    title="",
    showBackButton = true
})=>{
    const classes = useStyles();
    return (
        <Toolbar variant="dense">
            {(showBackButton)? <BackButton /> : null}
            <Typography
                variant="h6"
                className={classes.appBarTitle}
            >
                {title}
            </Typography>
            {children}
        </Toolbar>
    );
};

export default function PageWrapper({ children, toolbar }){
    const classes = useStyles();
    return (
        <Paper>
            <AppBar
                position="relative"
                className={classes.appBar}
            >
                {toolbar}
            </AppBar>
            {children}
        </Paper>
    )
}
import { useFirebase, useFirebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useAuthUid } from './useAuth';

export const useFirebasePlayersList = ()=>{
    useFirebaseConnect([
        'players',
    ]);
    const items = useSelector(state => state.firebase.ordered.players);
    return (isLoaded(items) && !isEmpty(items))? Object.keys(items).map((key) =>{
        return {
            ...items[key].value,
            id: items[key].key,
        };
    }): [];
}

export const useFirebaseMyProfile = () => {
    const uid = useAuthUid();
    useFirebaseConnect([
        'players/'+uid,
    ], [uid]);
    const player = useSelector(
        ({firebase: {data}}) => data.players && data.players[uid]
    );
    return {
        ...player,
        id: uid,
    };
}

export const useFirebasePushGame = () => {
    const firebase = useFirebase();
    return (slug, name) => {
        const key = firebase.ref().child('games').push().key;
        const updates = {};
        updates['/games/' + key] = {
            slug,
            name,
            scoreboards: [],
        };
        return firebase.database().ref().update(updates);
    }
};

export const useFirebaseGamesList = ()=>{
    useFirebaseConnect([
        'games',
    ]);
    const games = useSelector(state => state.firebase.ordered.games);
    return (isLoaded(games) && !isEmpty(games))? Object.keys(games).map((key, id) =>{
        return {
            ...games[key].value,
            id: games[key].key,
        };
    }): [];
};

export const useFirebaseGameBySlug = (slug) => {
    return useFirebaseGamesList().find(item => item.slug === slug);
}

export const useFirebasePushScoreboard = ()=>{
    const firebase = useFirebase();
    return (gameId, slug, name) => {
        const key = firebase.ref().child('scoreboards').push().key;
        const updates = {};
        updates[`/scoreboards/${key}`] = {
            gameId,
            name,
            slug,
            scores:[],
            createdAt: firebase.database.ServerValue.TIMESTAMP,
        };
        updates["/games/"+gameId+"/scoreboards/"+key] = true;

        return firebase.database().ref().update(updates);
    }
}

export const useFirebaseScoreboardsList = ()=>{
    useFirebaseConnect([
        'scoreboards',
    ]);
    const items = useSelector(state => state.firebase.ordered.scoreboards);
    return (isLoaded(items) && !isEmpty(items))? Object.keys(items).map((key, id) =>{
        return {
            ...items[key].value,
            id: items[key].key,
        };
    }): [];
}

export const useFirebaseScoreboardsListByGameId = (gameId)=>{
    return useFirebaseScoreboardsList().filter(item => item.gameId === gameId);
}

export const useFirebaseScoreboardByGameIdAndSlug = (gameId, slug)=>{
    return useFirebaseScoreboardsList().find(item => item.gameId === gameId && item.slug === slug);
}

export const useFirebasePushScore = () => {
    const firebase = useFirebase();
    return (scoreboardId, playerId, score) => {
        const key = firebase.ref().child('scores').push().key;
        const updates = {};
        updates[`/scores/${key}`] = {
            scoreboardId,
            playerId,
            score,
            createdAt: firebase.database.ServerValue.TIMESTAMP,
        };
        updates[`/scoreboards/${scoreboardId}/scores/${key}`] = true;
        updates[`/players/${playerId}/scores/${key}`] = true;
        return firebase.database().ref().update(updates);
    }
}

export const useFirebaseScoresList = ()=>{
    useFirebaseConnect([
        'scores',
    ]);
    const items = useSelector( state => state.firebase.ordered.scores );
    return (isLoaded(items) && !isEmpty(items))? Object.keys(items).map((key) =>{
        return {
            ...items[key].value,
            id: items[key].key,
        };
    }): [];
}

export const useFirebaseScoresListByScoreboardId = (scoreboardId) => {
    return useFirebaseScoresList().filter(item => item.scoreboardId === scoreboardId);
}
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import ScoresTable from '../ScoresTable';
import useStyles from '../../hooks/styleHooks';
import AddScore from '../AddScore';
import { useFirebaseGameBySlug, useFirebaseScoreboardByGameIdAndSlug, useFirebaseScoresListByScoreboardId } from '../../hooks/firebaseHooks';
import PageWrapper, { PageToolbar } from '../PageWrapper';

const ScoreBoardPage = ()=> {

    const {gameSlug, scoreBoardSlug} = useParams();

    const game = useFirebaseGameBySlug(gameSlug);
    const scoreboard = useFirebaseScoreboardByGameIdAndSlug(game && game.id, scoreBoardSlug);
    const scores = useFirebaseScoresListByScoreboardId(scoreboard && scoreboard.id);

    if(!game || !scoreboard || !scores) return <p>Loading</p>;


    return <PageWrapper
        toolbar={<PageToolbar  title={`${game.name} › ${scoreboard.name}`} />}
    >
        <Box p={2}>
            <AddScore scoreboardId={scoreboard.id} />
        </Box>
        <ScoresTable
            scores={scores}
        />
    </PageWrapper>
}

export default ScoreBoardPage;
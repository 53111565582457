import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import StartPage from './pages/StartPage';
import { PATH_TO_GAME, PATH_TO_SCOREBOARD, PATH_TO_PROFILE } from '../utils/links';
import { Container } from '@material-ui/core';
import GamePage from './pages/GamePage';
import ScoreBoardPage from './pages/ScoreBoardPage';
import { useAuthIsLoaded, useAuthIsSignedIn } from '../hooks/useAuth';
import LoginPage from './pages/LoginPage';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase from 'firebase/app';
import ProfilePage from './pages/ProfilePage';

const LoginOrStartRouter = () => {

    const isSignedIn = useAuthIsSignedIn();

    // if not signed in, goto login page
    if( !isSignedIn ){
        return <LoginPage />
    }

    // else start routing in app
    return <BrowserRouter>
        <Switch>
            <Route path={PATH_TO_PROFILE} component={ProfilePage} />
            <Route path={PATH_TO_SCOREBOARD} component={ScoreBoardPage} />
            <Route path={PATH_TO_GAME} component={GamePage} />
            <Route path="/" component={StartPage} />
        </Switch>
    </BrowserRouter>
}

const rrfConfig = {
    userProfile: 'users'
    // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

const App = ({store})=>{
    const rrfProps = {
        firebase,
        config: rrfConfig,
        dispatch: store.dispatch
    }
    return <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <Container>
                <LoginOrStartRouter />
            </Container>
        </ReactReduxFirebaseProvider>
    </Provider>;
}

export default App;
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Typography, AppBar, Toolbar, IconButton, Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ScoreBoardsList from '../ScoreBoardsList';
import AddIcon from '@material-ui/icons/Add';
import { actionAddScoreBoard } from '../../data/actions';
import AddScoreBoard from '../AddScoreBoard';
import { linkToScoreBoard } from '../../utils/links';
import { useFirebaseGameBySlug, useFirebaseScoreboardsListByGameId } from '../../hooks/firebaseHooks';
import PageWrapper, { PageToolbar } from '../PageWrapper';

const useStyles = makeStyles({
    appBar: {
        backgroundColor: "#0277BD",
    }
});

const GamePage = ()=> {
    const classes = useStyles();

    const history = useHistory();
    const {gameSlug} = useParams();

    const game = useFirebaseGameBySlug(gameSlug);
    const scoreboards = useFirebaseScoreboardsListByGameId(game && game.id);

    if(!game || !scoreboards) return <p>loading</p>;

    return <PageWrapper
        toolbar={<PageToolbar title={game.name} />}
    >
        <Box p={2}>
            <AddScoreBoard gameId={game.id} />
        </Box>
        <ScoreBoardsList
            scoreBoards={scoreboards}
            onClick={(scoreBoardSlug)=>history.push(linkToScoreBoard(gameSlug, scoreBoardSlug))}
        />
    </PageWrapper>
}

export default GamePage;
import { useFirebaseMyProfile } from "./firebaseHooks";
import { useFirebase } from "react-redux-firebase";



export const useMyUsername = ()=>{
    const firebase = useFirebase();
    const {id, name} = useFirebaseMyProfile();
    return [
        name,
        (value)=>{
            firebase.database().ref().update({
                ['/players/'+id+'/name']: value,
            })
        }
    ];
}
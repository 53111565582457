import { useSelector } from "react-redux";
import { objectToArrayWithId, filterScoresByScoreBoardId } from "../utils/transformations";

const useScores = ()=> useSelector(state => state.localStorage.scores);

export const useScoresList = () => objectToArrayWithId(useScores());

export const useScoresByScoreBoardId = ( scoreBoardId ) => filterScoresByScoreBoardId(
    useScoresList(),
    scoreBoardId
);
import { useSelector } from "react-redux";
import { objectToArrayWithId } from "../utils/transformations";
import { useScoreBoardById } from "./scoreboardHooks";
import { useScoresByScoreBoardId } from "./scoreHooks";

const usePlayers = () => useSelector(state => state.localStorage.players );

export const usePlayersList = () => objectToArrayWithId(usePlayers());

export const usePlayersMapByScoreBoardId = (scoreBoardId) =>{
    const playerIds = useScoresByScoreBoardId(scoreBoardId).map(b => b.playerId );
    const allPlayers = usePlayers();
    const players = {};
    for( const playerId of playerIds){
        if(typeof players[playerId] === typeof undefined) players[playerId] = {
            ...allPlayers[playerId],
            id: playerId,
        };
    }
    return players;
};
import React from 'react';
import GamesList from '../GamesList';
import { useHistory } from 'react-router-dom';
import { IconButton, Box } from '@material-ui/core';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import { linkToGame, PATH_TO_PROFILE } from '../../utils/links';
import AddGame from '../AddGame';
import { useFirebaseGamesList } from '../../hooks/firebaseHooks';
import { useFirebase } from 'react-redux-firebase';
import PageWrapper, { PageToolbar } from '../PageWrapper';
import { useMyUsername } from '../../hooks/currentPlayerHooks';


const StartPage = () => {

    const history = useHistory();

    const firebase = useFirebase();

    const [username] = useMyUsername();
    const games = useFirebaseGamesList();

    const handleLogOut = ()=>{
        firebase.auth().signOut();
    };
    const handleProfile = ()=>{
        history.push(PATH_TO_PROFILE)
    }

    return <PageWrapper
        toolbar={
            <PageToolbar
                showBackButton={false}
                title={`Hi ${username || ""}, let's play some games!`}
            >
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleProfile}
                >
                    <AccountCircleOutlinedIcon />
                </IconButton>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleLogOut}
                    >
                    <PowerSettingsNewOutlinedIcon />
                </IconButton>
            </PageToolbar>
        }
    >
        <Box p={2}>
            <AddGame />
        </Box>
        <GamesList
            games={games}
            onClick={(slug)=> history.push(linkToGame(slug)) }
        />
    </PageWrapper>
}

export default StartPage;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    appBar: {
        backgroundColor: "#0277BD",
    },
    appBarTitle:{
        flexGrow: 1
    },
});

export default useStyles;